import { getAuthTokenHeader } from '~/shared/services/auth';

type CacheData<T> = {
  ttl: number;
  data: T;
};

export const useChFetchCacheBust = (id: string) => {};

export const useChFetch = async <T>(id: string, url: string, query?: Record<string, any>, ttl = 1000 * 60) => {
  const { apiUrl } = useSafeEnv();
  const headers = getAuthTokenHeader();

  const wholeUrl = `${apiUrl.value}${url}`;

  const finalData = ref<T | undefined>();

  const loadFromAPI = async () => {
    const { data, error } = await useFetch<T>(wholeUrl, { headers, query });

    if (error.value) {
      throw createError({
        ...error.value,
        statusMessage: `Could not fetch data from ${url}`,
      });
    }

    finalData.value = data.value as T;

    if (import.meta.server) return;

    set({ data: data.value, ttl: new Date().getTime() + ttl } as CacheData<T>);
  };

  if (import.meta.server) {
    await loadFromAPI();

    return finalData;
  }

  const { returned, set } = await useSessionStorage<CacheData<T>>(id);

  const unwrapped = returned?.value;

  if (!unwrapped) {
    await loadFromAPI();

    return finalData;
  }

  const { data: cachedData, ttl: cachedTtl } = unwrapped;

  if (cachedTtl - Date.now() <= 0) {
    await loadFromAPI();

    return finalData;
  }

  finalData.value = cachedData;

  return finalData;
};
