import { PaginatedResponse, Collection, Character, WorldsInfo } from './../types/static-types';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { chAxios } from '@/shared/lib/axios';

export async function createCollection(payload: {
  name?: string;
  description?: string;
  image_url?: string;
  cropped_img_url?: string;
  banner_img_url?: string;
  user?: string;
}) {
  const { data } = await chAxios().post(`/collections/`, payload);

  sendAnalyticsEvent('Created collection');
  return data;
}

export async function getCollectionFromSlug(slug?: string) {
  const url = `/collections/from-slug/`;

  const { data } = await chAxios().get(url, { params: { slug } });
  return data;
}

export async function addCharacterInCollection(payload: { id: string; character_id: {} }) {
  const { data } = await chAxios().post(`/collections/add-character-in-collection/`, payload);

  sendAnalyticsEvent('Added Characters in Collection');

  return data;
}

export async function removeObjectInCollection(payload: { collection: string; ids: string[] }) {
  const { data } = await chAxios().post(`/collections/remove/`, payload);

  sendAnalyticsEvent('Added Characters in Collection');

  return data;
}

export async function addWorldInCollection(payload: { id: string; world_id: {} }) {
  const { data } = await chAxios().post(`/collections/add-world-in-collection/`, payload);

  sendAnalyticsEvent('Added Worlds in Collection');

  return data;
}

export async function addObjectToCollection(payload: { collection: string; id: string; type: string }) {
  const { data } = await chAxios().post(`/collections/add-object/`, payload);
  sendAnalyticsEvent('Added Object to Collection');

  return data;
}

export async function getCharactersForCollection(id: string, page = 1, page_size = 10) {
  const url = `/collections/get-characters-from-collection/`;

  const { data } = await chAxios().get(url, { params: { id, page, page_size } });
  return data;
}

export async function getMyCreationsCharactersForFolder(id: string) {
  const url = `/collections/get-characters-from-collection/`;

  const cacheKey = `${id}-my-creations-folder-characters`;
  const value = await useChFetch<PaginatedResponse<Character[]>>(cacheKey, url, { id }, 30000 * 60);

  return value;
}

export async function getMyCreationsWorldsForFolder(id: string) {
  const url = `/collections/get-worlds-from-collection`;

  const cacheKey = `${id}-my-creations-folder-worlds`;
  const value = await useChFetch<PaginatedResponse<WorldsInfo[]>>(cacheKey, url, { id }, 30000 * 60);

  return value;
}

export async function getWorldsForCollection(id: string, page = 1, page_size = 10) {
  const url = `/collections/get-worlds-from-collection`;

  const { data } = await chAxios().get(url, { params: { id, page, page_size } });
  return data;
}
export async function getCharacterCollections(user: string, page?: number, params = {}) {
  const url = `/collections/`;

  const { data } = await chAxios().get(url, { params: { user, page, ...params } });
  return data;
}

export async function getCollection(id: string) {
  const url = `/collections/${id}/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function updateCollection(
  id: string,
  collectionData: {
    name?: string;
    description?: string;
    image_url?: string;
    cropped_img_url?: string;
    banner_img_url?: string;
    user?: string;
  }
) {
  const url = `/collections/${id}/`;

  const { data } = await chAxios().patch(url, collectionData);
  sendAnalyticsEvent('Updated collection');
  return data;
}

export async function deleteCollection(id: string) {
  const url = `/collections/${id}/`;
  const { data } = await chAxios().delete(url);
  sendAnalyticsEvent('Deleted collection');
  return data;
}

export async function getProfileFolders(userId: string, page = 1) {
  const url = `/collections/profile/`;
  const { data } = await chAxios().get<PaginatedResponse<Collection>>(url, {
    params: {
      page,
      user: userId,
    },
  });

  return data;
}

export async function getMyCreationsBarFolders(userId: string, page = 1) {
  const url = `/collections/profile/`;
  const cacheKey = `my-creations-folders`;
  const value = await useChFetch<PaginatedResponse<Collection[]>>(
    cacheKey,
    url,
    {
      page,
      user: userId,
    },
    30000 * 60
  );

  return value;
}

export async function getFoldersInsideFolder(folderId: string, page = 1, page_size = 10) {
  const url = `/collections/parent/`;
  const { data } = await chAxios().get<PaginatedResponse<Collection[]>>(url, {
    params: {
      page,
      id: folderId,
      page_size,
    },
  });

  return data;
}

export async function addFoldersInsideFolder(folderId: string, subfoldersIds: string[]) {
  const url = `/collections/add-collection-in-collection/`;
  const { data } = await chAxios().post(url, {
    children_ids: subfoldersIds,
    id: folderId,
  });

  return data;
}
