import { authStore } from '../pinia-store/auth';
import { mainStore } from '../pinia-store/main';

export const isElementInViewport = (
  el: HTMLElement,
  outboundEl?: HTMLElement,
  isBackground?: Boolean,
  offsetX?: number,
  offsetY?: number
) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  try {
    let outboundWidth = 0;
    let outboundHeight = 0;

    if (outboundEl) {
      outboundWidth = outboundEl?.clientWidth || 0;
      outboundHeight = outboundEl?.clientHeight || 0;
    }

    const rect = el.getBoundingClientRect();
    const compareHeight = windowHeight.value - (windowHeight.value - outboundHeight) / 2;
    const compareWidth = isBackground ? windowWidth.value : windowWidth.value - (windowWidth.value - outboundWidth) / 2;
    const compareLeft = isBackground ? 0 : (windowWidth.value - outboundWidth) / 2 - (offsetX || 0);

    const rectTop = rect.top - (offsetY || 0);
    const rectBottom = rect.top - (offsetY || 0);
    const rectLeft = rect.left;

    const rectRight = rect.right - (offsetX || 0);

    const value = rectTop >= 0 && rectLeft >= compareLeft && rectBottom <= compareHeight && rectRight <= compareWidth;

    const y = value ? '-' : rectTop >= 0 && rectBottom <= compareHeight;

    const x = value ? '-' : rectLeft >= compareLeft && rectRight <= compareWidth;

    return {
      value,
      y,
      x,
      xDetailed: { left: rectLeft >= compareLeft, right: rectRight <= compareWidth },
      yDetailed: {
        top: rectTop >= 0,
        bottom: rectBottom <= compareHeight,
      },
    };
  } catch (error) {
    return {
      value: null,
      y: null,
      x: null,
      xDetailed: {
        right: null,
        left: null,
      },
    };
  }
};

export const isTouchScreen = () => {
  try {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || window.matchMedia('(any-hover: none)').matches;
  } catch (error) {
    return undefined;
  }
};

export const docHeight = () => {
  try {
    const doc = document.documentElement;
    const height = window.visualViewport?.height || window.innerHeight;

    const { setInitHeight, initHeight, dark } = mainStore();
    const { isAuthenticated } = authStore();

    setInitHeight(height);

    doc.style.setProperty('--doc-height', `${initHeight.value}px`);

    const body = document.querySelector('body');

    if (body) {
      body.style.backgroundColor = dark.value ? '#010123' : isAuthenticated.value ? '#F3F3F3' : '#F9F9FF';
    }
  } catch (error) {}
};
