<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div class="title">
            Send {{ get(virtualItem, 'title') }} {{ get(virtualItem, 'item_group.title') }} as a Gift
          </div>

          <div />
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content p-3">
      <div class="items-border-section p-2">
        <div class="d-flex align-items-center">
          <ion-icon class="icon mx-1" :icon="giftOutline" />
          <span class="sub-title"> Item to be Gifted:</span>
        </div>
        <VirtualItemsDisplay :item="virtualItem" />
      </div>

      <div class="items-border-section p-2 mt-2">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <ion-icon class="icon mx-1" :icon="personCircleOutline" />
            <span class="sub-title">Recipient:</span>
          </div>
          <div>
            <ion-button class="change-btn" @click.prevent="emits('change')">
              <ion-icon class="mr-1" style="font-size: 18px !important" :icon="repeatOutline" />
              Change User</ion-button
            >
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <img v-user-image class="prf-pic mr-1" :src="get(selectedUser, 'profile_picture_url')" />
          <router-link :to="{ name: 'profile', params: { username: get(selectedUser, 'username') } }">
            @{{ get(selectedUser, 'username') }}
          </router-link>
        </div>
      </div>
    </div>

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button color="transparent" class="gift-btn m-2" @click.prevent="action" :disabled="isLoading">
            <ChLoading size="sm" v-if="isLoading" />
            <div v-else class="d-flex align-items-center">
              <ion-icon class="icon mx-1" :icon="giftOutline" /> Gift it for
              <div class="mx-1 token">
                <CurrencyImage :img-width="20" />
                <inline-svg :src="imageFromItemsCurrency" class="svg" />
              </div>

              {{ get(virtualItem, 'amount') }}
            </div>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import VirtualItemsDisplay from '@/shared/pages/store/VirtualItemsDisplay.vue';
import { repeatOutline, personCircleOutline, giftOutline } from 'ionicons/icons';
import CurrencyImage from '@/shared/components/CurrencyImage.vue';
import { useVirtualItemCurrencyImage } from '~/composables/useVirtualItemCurrencyImage';
import { giftVirtualItem } from '../actions/virtualItems';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  virtualItem: {
    type: Object,
    default: {},
  },
  selectedUser: {
    type: Object,
    default: {},
  },
});
const virtualItem = toRef(props, 'virtualItem');
const selectedUser = toRef(props, 'selectedUser');
const emits = defineEmits(['close', 'gift', 'change']);
const { imageFromItemsCurrency } = useVirtualItemCurrencyImage(virtualItem.value);
const isOpen = toRef(props, 'isOpen');
const isLoading = ref(false);
const dismissModal = () => {
  emits('close');
};
const action = async () => {
  isLoading.value = true;
  const res = await giftVirtualItem({ entity_id: virtualItem.value.id, beneficiary: selectedUser.value.id });

  emits('gift');
  setTimeout(() => {
    isLoading.value = false;
  }, 50);
};
</script>
<style lang="sass" scoped>
.icon
  font-size: 20px !important
  font-weight: bold
.token
  height: 20px
.svg
  width: 20px
  height: 20px
.prf-pic
  width: 20px
  height: 20px
  object-fit: cover
  border-radius: 50px
.change-btn
  --background: linear-gradient(90deg,#7ee0ff66,#5fa9ee00),#a83cff
  --border-radius:20px
.items-border-section
  border: 2px solid #DDDFE9
  border-radius: 16px
.dark .toolbar
  --background: #17074c !important
.gift-btn
  border-radius: 18px
  border: 2px solid  #DDDFE9
  text-transform: none
  color: #214163
  height: 35px
  font-weight: 600
.dark .gift-btn
  color: white
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 70vh
  overflow: auto
  max-height: 70dvh

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 16px
  font-weight: bold
  color: #214163
.dark .sub-title
  color: white
.title
  color: #214163
  font-size: 20px
</style>
