<template>
  <div v-if="images?.length">
    <div class="fade-top"></div>
    <div id="chsgi" class="scrollable my-3">
      <!-- <div class="mobile-only offset" :class="{ off: !topRowReactionsOpen }"></div> -->
      <Sortable
        :id="`images_${currentCollectionId}`"
        :key="forcererenderKey"
        class="images grid-container"
        item-key="id"
        tag="div"
        :list="images"
        :options="sortableOptions"
        :class="{ 'is-disabled': route.name === 'home' }"
        :disabled="!isEditor || isReadOnlyCollection"
        @end="onEndMove"
        @move="onMove"
        @clone="onClone"
      >
        <template #item="{ element, index }">
          <InlineGalleryImage
            :reordering="isReordering"
            :id="element.id"
            :is-editor="isEditor"
            :index="index"
            :customize="customize"
            :disabled="isReadOnlyCollection"
            @openEditModal="emits('openEditModal', element.id)"
            @hideReactions="toggleReactionBump(false)"
            @showReactions="toggleReactionBump(true)"
            @touched="emitTouched"
          />
        </template>
      </Sortable>
    </div>
    <div class="fade-bottom"></div>
  </div>
</template>
<script lang="ts" setup>
import InlineGalleryImage from './InlineGalleryImage.vue';
import { imageStore } from '@/shared/pinia-store/images';
import { mainStore } from '@/shared/pinia-store/main';

import { isTouchScreen } from '@/shared/utils/ui';

const props = defineProps({
  isEditor: {
    type: Boolean,
    default: false,
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
  reordering: {
    type: Boolean,
    default: false,
  },
});
const { isTempDefault } = useCustTempDefault(props);
const clonedElement = ref(null) as any;
const forcererenderKey = ref(0);
const emits = defineEmits(['touched', 'openEditModal']);
const isEditor = toRef(props, 'isEditor');
const isReordering = toRef(props, 'reordering');
const route = useRoute();
const sortableOptions = {
  group: {
    name: 'imgmove',
    pull: true,
    put: false,
  },
  filter: '.drag-prio',
  draggable: '.inner-i-d',
  preventOnFilter: false,
  fallbackOnBody: true,
  animation: 50,
  forceAutoScrollFallback: true,
  scrollSpeed: 15,
  delay: isTouchScreen() === false ? 0 : 130,
  touchStartThreshold: 5,
  fallbackTolerance: 5,
  chosenClass: 'held',
  swapThreshold: 1,
};
const customize = toRef(props, 'customize');
const topRowReactionsOpen = ref(false);
const emitTouched = () => emits('touched');

const images = computed(() => {
  const { visibleImageCollectionImages } = imageStore();
  return (visibleImageCollectionImages.value || []).filter(Boolean);
});

const currentCollectionId = computed(() => {
  const { visibleImageCollectionId } = imageStore();
  return visibleImageCollectionId.value;
});

const isDark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const isReadOnlyCollection = computed(() => {
  const { isVisibleImageCollectionReadOnly } = imageStore();
  return isVisibleImageCollectionReadOnly.value;
});

const infoSectionColor = computed(() => {
  const defaultClr = isDark.value ? '#17074C' : '#E6E6E6';
  return isTempDefault.value ? defaultClr : get(customize.value, 'data.theme.backgrounds.infoSection') || defaultClr;
});

const onMove = (e: any) => {
  const event = e;
  const movingIntoCollection = event.to?.id?.startsWith('ih');
  if (isReadOnlyCollection.value) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
  if (movingIntoCollection) {
    const { visibleImageCollectionId } = imageStore();
    if (visibleImageCollectionId.value === event.to.id.replace('ih_', '')) {
      return false;
    }
  }
};
const onClone = (e: any) => {
  clonedElement.value = e.item;
};
const onEndMove = (e: any) => {
  const { changeImageOrderLocal, visibleImageCollectionId } = imageStore();
  if (!visibleImageCollectionId.value || isReadOnlyCollection.value) {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    clonedElement.value = null;
    return false;
  }
  const newImgs = [...images.value];
  newImgs.splice(e.newIndex, 0, newImgs.splice(e.oldIndex, 1)[0]);
  changeImageOrderLocal({
    collectionId: visibleImageCollectionId.value!,
    from: e.oldIndex,
    to: e.newIndex,
  });
  clonedElement.value = null;
  // if dropped in another collection, rerender
  if (e.to.id.split('_')?.[1] === visibleImageCollectionId.value && e.oldIndex === e.newIndex) {
    nextTick(() => {
      forcererenderKey.value++;
    });
  }
};
const toggleReactionBump = (open: boolean) => {
  // if current screen width is over 500px do not do anything
  if (window.innerWidth > 500) return;
  topRowReactionsOpen.value = open;
  document.getElementById('chsgi')?.scrollTo(0, 0);
};
watch(
  () => currentCollectionId.value,
  (newId, oldId) => {
    if (newId === oldId) return;
    // attach eventlisteners to all images
  }
);
</script>
<style scoped lang="sass">
.grid-container
  grid-template-columns: repeat(2, 1fr)
  grid-template-rows: auto
  grid-gap: 0rem 0.5rem
  display: grid
  padding-bottom: 1rem
  padding-top: 1rem
  padding: 2px
.fade-top
  position: absolute
  top: 0
  left: 0
  right: 0
  height: 30px
  z-index: 2
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, v-bind(infoSectionColor) 100%)
  pointer-events: none
.fade-bottom
  position: absolute
  bottom: 0
  left: 0
  right: 0
  height: 30px
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, v-bind(infoSectionColor) 100%)
  z-index: 2
  pointer-events: none
.scrollable
  position: relative
  max-height: min(80vh, 600px)
  overflow-y: auto
  overflow-x: hidden
.mobile-only
  display: none
@media(max-width: 500px)
  .scrollable
    max-height: min(57vh, 375px)
  .mobile-only
    display: block
.offset
  transition: height 150ms ease
  height: 70px
  width: 1px
  display: block
.off
  height: 1px
  overflow: hidden
.held
  outline: 2px solid rgba(174, 56, 229, 0.3) !important
  border-radius: 10px
  opacity: 0.8
  top: 10px
  left: 10px
</style>
