<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div class="title">Select User</div>
          <div />
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="px-3 py-2">
        <div class="sub-title py-2">Select User to gift Ring</div>
        <div>
          <SearchInput
            v-model="text"
            :blur-on-esc="true"
            :select-on-focus="false"
            :shortcut-listener-enabled="true"
            :clear-icon="false"
            :hide-shortcut-icon-on-blur="false"
            :clear-on-esc="true"
            placeholder="Search Users here"
            @input="onSearch"
          />

          <compliment-bomb-users-tabs
            class="mt-3"
            :is-items="true"
            :tabs="tabs"
            :active-index="tabIndex"
            @tab-changed="tabChanged"
          />

          <div v-if="isLoading" class="d-flex justify-content-center mt-2">
            <ChLoading size="lg" class="spinner" />
          </div>
          <Grid v-else :scrollSm="false" :sm="2">
            <compliment-bomb-users-card
              v-for="(user, index) in allUsers"
              :key="index"
              :user="user"
              class="grid-item"
              :is-virtual-items="true"
              :selected-user="selectedUserId === user.id || selectedUserId === get(user, 'following_id')"
              @click="isUserSelected(user)"
            />
            <ChLoading size="sm" v-if="isUserLoading" class="spinner" />
            <p v-else-if="nextPageExists && !isUserLoading" class="clickable" @click="requestLoadMore">Load More</p>
          </Grid>

          <div
            class="d-flex text-center justify-content-center"
            v-if="!isEmpty(text) && !isLoading && isEmpty(allUsers)"
          >
            No search results found
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="sendGift" :disabled="!selectedUserId"
            ><span style="color: white"> Select</span></ion-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import SearchInput from 'vue-search-input';
import ComplimentBombUsersTabs from '@/shared/pages/compliment-bombs/components/ComplimentBombCharactersTabs.vue';
import { complimentBombUserTabs } from '@/shared/statics/tabs';
import Grid from '@/shared/components/storage/Grid.vue';
import { Paging, Tab } from '@/shared/types/static-types';
import ComplimentBombUsersCard from '@/shared/pages/compliment-bombs/components/ComplimentBombUsersCard.vue';
import { getGiftUsers } from '@/shared/actions/users';
import { authStore } from '@/shared/pinia-store/auth';
import { getNextPage } from '@/shared/actions/follow';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  selectedGift: {
    type: Object,
    default: {},
  },
  item: {
    type: Object,
    default: {},
  },
});

const emits = defineEmits(['close', 'send']);
const selectedUserId: any = ref('');
const text = ref('');
const isOpen = toRef(props, 'isOpen');
const selectedUser = ref({});
const item = toRef(props, 'item');
const tabs = ref(complimentBombUserTabs);
const tabIndex = ref(0);
const allUsers: Ref<any[]> = ref([]);
const currentTab = ref('myfollowing');
const { user } = authStore();
const isLoading = ref(true);
const userPaging: any = ref<Paging>();
const isUserLoading = ref(false);
const fetchAllUsers = async (page = 1, filterType?: any) => {
  isLoading.value = true;
  const { results, ...paging } = await getGiftUsers(page, 50, {
    item_id: item.value.id,
    ...(text.value && { search: text.value }),
    ...(filterType && { filter_type: filterType }),
  });
  allUsers.value = results;
  userPaging.value = paging;
  isLoading.value = false;
};
const fetchUsersOnTabChanged = () => {
  currentTab.value === 'all'
    ? fetchAllUsers()
    : currentTab.value === 'myfollowing'
    ? fetchAllUsers(1, 'following')
    : fetchAllUsers(1, 'followers');
};
const getTabIndex = () => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === currentTab.value)
  );
  if (tIndex !== -1) {
    tabIndex.value = tIndex;
  }

  tabIndex.value = tIndex;
  fetchUsersOnTabChanged();
};
const reset = () => {
  text.value = '';
  selectedUserId.value = '';
  allUsers.value = [];
  userPaging.value = null;
};

watch(isOpen, () => {
  if (isOpen.value) {
    getTabIndex();
    reset();
  }
});

watch(currentTab, async () => {
  if (currentTab.value) {
    reset();
    getTabIndex();
  }
});

const nextPageExists = computed(() => {
  return !!get(userPaging.value, 'next');
});

const isUserSelected = (user: any) => {
  const userId = selectedUserId.value;
  const followingId = get(user, 'following_id');

  selectedUserId.value = userId === user.id || userId === followingId ? '' : user.id || followingId;
  selectedUser.value = user || get(user, 'following');
};
const userCharactersNextPage = async () => {
  isUserLoading.value = true;
  const { results, ...paging } = await getNextPage(userPaging.value!);
  allUsers.value = allUsers.value.concat(results);
  userPaging.value = paging;
  isUserLoading.value = false;
};
const requestLoadMore = async (ev: MouseEvent) => {
  if (!userPaging.value.next) {
    (ev?.target as any).complete();
  } else {
    await userCharactersNextPage();
  }
};

const dismissModal = () => {
  emits('close');
  reset();
};

const onSearch = () => {
  if (!isLoading.value) searchTextChanged();
};

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
};

const sendGift = async () => {
  emits('send', selectedUser.value);
};

const searchTextChanged = debounce(async () => {
  fetchUsersOnTabChanged();
}, 500);
</script>
<style lang="sass" scoped>
.grid-item
  @media(max-width:500px)
    width: calc(51% - 8px) !important
    min-width: calc(51% - 8px) !important
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #f2f2f2)
  max-height: 70vh
  overflow-x: hidden
  overflow-y: auto

.modal-big
  --width: 500px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
.sub-title
  color: #214163
  font-size: 18px
  font-weight: bold
.dark .sub-title
  color: white
</style>
