export const useVirtualItemCurrencyImage = (item: any) => {
  const imageFromItemsCurrency = computed(() => {
    const type = get(item, 'currency_type');

    if (type === 'coin') {
      return '/hubuck.svg';
    } else if (type === 'gem') {
      return '/gem.svg';
    }
    return null;
  });
  return { imageFromItemsCurrency };
};
