<template>
  <div>
    <div v-if="!isEmpty(items)">
      <Grid :xl="4" :lg="4" :md="4" :sm="3" :scrollSm="false">
        <VirtualItemsCard
          v-for="(item, index) in items"
          :key="index"
          :item="get(item, 'virtual_item') || item"
          class="grid-item"
          :selected-ring="selectedRing === (get(item, 'virtual_item.id') || get(item, 'id'))"
          @click="ringSelection(item)"
          :white-card-color="whiteCardColor"
          @updated="emits('updated')"
          @fetch="emits('fetch')"
          :amountSection="amountSection"
          :prfaddOns="prfaddOns"
          :selectedUserChar="selectedUserChar"
        />
      </Grid>
    </div>
    <div v-else class="no-data">No featured rings available. Visit Ring Shop for more options.</div>
    <div class="d-flex justify-content-end" v-if="ringShopBtn">
      <ion-button class="shop-btn" @click="goToShop">Go to the Ring Shop</ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import VirtualItemsCard from '@/shared/components/VirtualItemsHorizontalCard.vue';

const props = defineProps({
  items: { type: Array, default: [] },
  whiteCardColor: { type: Boolean, default: false },
  amountSection: { type: Boolean, default: false },
  ringShopBtn: { type: Boolean, default: false },
  profileRing: { type: Object, default: {} },
  character: { type: Object, default: {} },
  selectedUserChar: { type: Object, default: {} },
  prfaddOns: { type: Boolean, default: false },
});
const items = toRef(props, 'items');
const selectedRing = ref(null);
const profileRing = toRef(props, 'profileRing');
const character = toRef(props, 'character');
const router = useRouter();
watch(character, () => {
  selectedRing.value = character.value.profile_ring.id;
  emits('selected', character.value.profile_ring);
});
watch(
  profileRing,
  () => {
    if (profileRing.value) {
      selectedRing.value = isEmpty(profileRing.value)
        ? get(items.value[0], 'id')
        : get(profileRing.value, 'virtual_item.id') || get(profileRing.value, 'id') || profileRing.value;
    }
  },
  { immediate: true }
);

const goToShop = () => {
  router.push({ name: 'shop', query: { tab: 'profile-rings-shop', title: 'featured' } });
};
const emits = defineEmits(['selected', 'updated', 'fetch']);
const ringSelection = (ring: any) => {
  emits('selected', ring);
};
</script>

<style lang="sass" scoped>
.grid-item
  @media(max-width:1300px) and (min-width:1184px)
    width: calc(33% - 2px) !important
  @media(max-width:850px) and (min-width:625px)
    width: calc(33% - 2px) !important
  @media(max-width:625px) and (min-width:500px)
    width: calc(33% - 5px) !important
  @media(max-width: 515px)
    width: calc(50.333333% - 5px) !important
.shop-btn
  --background: linear-gradient(to right,rgb(189 71 216) 21%,rgb(108 83 206) 73%,rgb(94 93 206) 98%,rgb(86 98 206) 100%)
</style>
