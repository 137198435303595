import { defineStore, storeToRefs } from 'pinia';
import { authStore } from './auth';
import { fetchSiteMessageByName } from '@/shared/actions/main';

import { featureFlags } from '@/shared/config/feature-flags';
import constants from '@/shared/statics/constants';
import { capitalizeFirstLetterInPhrase } from '../utils/string';
import { statusBar } from '@/apps/mobile/native/status-bar';

interface Tab {
  name?: string;
  value?: string;
  type?: string;
  route?: string;
  tabName?: string;
  withBadge?: boolean;
  icon?: any;
}

interface SearchFilter {
  from: string;
  until: string;
  username: string;
  order: string;
}

export interface IMainState {
  _dark: boolean;
  _keyboardOpen: boolean;
  _keyboardWillOpen: boolean;
  _networkConnected: boolean;
  _cacheTest: string;
  _networkInitialized: boolean;
  _pushInitialized: boolean;
  _activeTab: number;
  _isCreating: boolean;
  _header: string;
  _routerHistory: any[];
  _lastRoute: string;
  _networkStatus: any;
  _tabs: Tab[];
  _categoryTabs: any[];
  _searchFilters: SearchFilter;
  _homeCarousel: any[];
  _siteMessages: any;
  _lightRope?: boolean;
  _snow?: boolean;
  _pageName?: string;
  _initHeight: number;
}

export const useMain = defineStore('main', {
  persist: { enabled: true },
  state: (): IMainState => ({
    _initHeight: 0,
    _dark: false,
    _keyboardOpen: false,
    _keyboardWillOpen: false,
    _networkConnected: true,
    _cacheTest: '',
    _networkInitialized: false,
    _pushInitialized: false,
    _activeTab: 0,
    _isCreating: false,
    _header: 'default',
    _routerHistory: [],
    _lastRoute: '/home',
    _networkStatus: {
      connected: true,
    },
    _tabs: [
      {
        route: '/home',
        tabName: 'home',
        withBadge: true,
        icon: 'homeOutline',
      },
      {
        route: '/search',
        tabName: 'search',
        withBadge: false,
        icon: 'searchOutline',
      },
      {
        tabName: 'create',
        withBadge: false,
        icon: 'createOutline',
      },
      {
        route: '/notifications',
        tabName: 'notifications',
        withBadge: true,
        icon: 'notificationsOutline',
      },
      {
        route: '/chat',
        tabName: 'chat',
        withBadge: true,
        icon: 'chatboxOutline',
      },
    ],
    _categoryTabs: [],
    _searchFilters: {
      from: '',
      until: '',
      username: '',
      order: '',
    },
    _homeCarousel: [],
    _siteMessages: {},
    _snow: featureFlags.isXmas,
    _lightRope: featureFlags.isXmas,
    _pageName: 'Home',
  }),
  actions: {
    setInitHeight(value: number) {
      this._initHeight = value;
    },
    updateNetwork(value: boolean) {
      this._networkConnected = value;
    },
    updateNetworkInit(value: boolean) {
      this._networkInitialized = value;
    },
    updatePushInit(value: boolean) {
      this._pushInitialized = value;
    },
    toggleSnow() {
      this._snow = !this._snow;
    },
    toggleLightRope() {
      this._lightRope = !this._lightRope;
    },
    toggleDarkness() {
      this._dark = !this._dark;
      statusBar.toggleDarkness(this._dark);
    },
    setKeyboardOpen(value: boolean) {
      this._keyboardOpen = value;
    },
    setKeyboardWillOpen(value: boolean) {
      this._keyboardWillOpen = value;
    },
    setNetworkConnected(value: boolean) {
      this._networkConnected = value;
    },
    setCacheTest(value: string) {
      this._cacheTest = value;
    },
    SET_NETWORK_STATUS(value: any) {
      this._networkStatus = value;
    },
    SET_HOME_CAROUSEL(homeCarousel: any) {
      this._homeCarousel = homeCarousel;
    },
    CLEAR_SEARCH_FILTERS() {
      this._searchFilters = {
        from: '',
        until: '',
        username: '',
        order: '',
      };
    },
    APPEND_ROUTE(route: string) {
      const routerHistory = [...this.routerHistory];
      const lastRoute = routerHistory[routerHistory.length - 1];
      const pushedRouter = route === '/' ? '/home' : route;
      if (lastRoute !== route) {
        routerHistory.push(pushedRouter);
      }

      this._routerHistory = routerHistory;
    },
    BACK() {
      const routerHistory = [...this.routerHistory];
      routerHistory.pop();

      this._routerHistory = routerHistory;
      const lastRoutePath = this.lastRoute;
      const router = useRouter();
      router.push({ path: lastRoutePath, query: { back: 1 } });
    },
    SET_ACTIVE_TAB(value: number) {
      this._activeTab = value;
    },
    SET_HEADER(value: string) {
      this._header = value;
    },
    SET_IS_CREATING(value: boolean) {
      this._isCreating = value;
    },
    SET_SEARCH_FILTERS(searchObject: any) {
      this._searchFilters = {
        ...this._searchFilters,
        ...searchObject,
      };
    },
    TOGGLE_MAIN_SCROLL(_: any, value: string) {
      const overflow = value ? 'scroll' : 'hidden';
      const document = useDocument();
      const scroller = document.value?.querySelector('.scroller') as HTMLElement;
      if (scroller) {
        scroller.style.overflow = overflow;
      }
    },
    SET_CATEGORY_TABS(value: any) {
      this._categoryTabs = value;
    },
    SET_SITE_MESSAGE({ name, message }: any) {
      this._siteMessages[name] = message;
    },
    SET_PAGE_NAME(name: string) {
      this._pageName = name;
    },
    clearSearchFilters() {
      this.CLEAR_SEARCH_FILTERS();
    },
    appendRoute(route: string) {
      this.APPEND_ROUTE(route);
    },
    back() {
      this.BACK();
    },
    setHeader(type: string) {
      this.SET_HEADER(type);
    },
    toggleScroll(value: string) {
      this.TOGGLE_MAIN_SCROLL(null, value);
    },
    setSearchFilter(searchObject: string) {
      this.SET_SEARCH_FILTERS(searchObject);
    },
    setIsCreating(value: boolean) {
      this.SET_IS_CREATING(value);
    },
    setNetworkStatus(value: string) {
      this.SET_NETWORK_STATUS(value);
    },
    initCategoryTabsasync() {
      const categories = Object.assign({ none: 'Featured' }, constants.categories);
      constants.onboardingExcludeCategories.map((code: any) => {
        delete categories[code];
      });
      const selected = ['none'];
      const { user } = authStore();
      user.value.categories = user.value.categories || [];

      user.value.categories.map((c: any) => {
        selected.push(c);
      });

      const alreadyFollowingTags = { tags: [] };
      const outputCategories: Tab[] = [
        {
          name: 'All',
          value: '',
          type: 'category',
        },
      ];
      alreadyFollowingTags.tags.forEach((value: any) => {
        const name = capitalizeFirstLetterInPhrase(value.replace('-', ' '));
        const type = 'tag';
        outputCategories.push({
          name,
          type,
          value,
        });
      });
      this.SET_CATEGORY_TABS(outputCategories);
    },
    async getSiteMessageByName(messageName: string) {
      let data = { name: messageName, message: null };
      try {
        const res = await fetchSiteMessageByName(messageName);
        data = res.value;
      } finally {
        this.SET_SITE_MESSAGE(data);
      }
      return data;
    },
    setPageName(name: string) {
      this.SET_PAGE_NAME(name);
    },
  },
  getters: {
    initHeight(): any {
      return this._initHeight;
    },
    dark(): any {
      return this._dark;
    },
    snow(): any {
      return this._snow;
    },
    lightRope(): any {
      return this._lightRope;
    },
    tabs(): any {
      return this._tabs;
    },
    keyboardOpen(): any {
      return this._keyboardOpen;
    },
    keyboardWillOpen(): any {
      return this._keyboardWillOpen;
    },
    networkConnected(): any {
      return this._networkConnected;
    },
    cacheTest(): any {
      return this._cacheTest;
    },
    networkInitialized(): any {
      return this._networkInitialized;
    },
    pushInitialized(): any {
      return this._pushInitialized;
    },
    networkStatus(): any {
      return this._networkStatus;
    },
    homeCarousel(): any {
      return this._homeCarousel;
    },
    activeTab(): any {
      return this._activeTab;
    },
    header(): any {
      return this._header;
    },
    isCreating(): any {
      return this._isCreating;
    },
    searchFilters(): any {
      return this._searchFilters;
    },
    categoryTabs(): any {
      return this._categoryTabs;
    },
    routerHistory(): any {
      return this._routerHistory;
    },
    lastRoute(): any {
      const routerHistory = [...this._routerHistory];
      const lastRoute = routerHistory[routerHistory.length - 1];
      return lastRoute;
    },
    siteMessageByName(): any {
      return (name: string) => {
        return this._siteMessages[name];
      };
    },
    pageName(): any {
      return this._pageName;
    },
  },
});

export const mainStore = () => {
  const store = useMain();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
