<template>
  <div>
    <ion-item class="item-container clickable-item-hov">
      <div class="menu-item text-black mx-auto">
        <small>Not Enough {{ currencyLabel }}!</small>
      </div>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  instance: {
    type: Object,
  },
  currencyType: {
    type: String,
    default: 'coins',
  },
});
const currencyType = toRef(props, 'currencyType');
const currencyLabel = computed(() => (currencyType.value === 'gem' ? 'Gems' : 'Coins'));
</script>

<style scoped lang="sass">
.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important
</style>
