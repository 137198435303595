import { modalController } from '@ionic/vue';
import { handleColorMode } from '~/apps/mobile/services/native';
import { docHeight } from '~/shared/utils/ui';

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return;

  docHeight();

  if (to.path !== from.path && process.client) {
    try {
      const scroller = document.querySelector('.layout-scroll-area');

      if (scroller) {
        scroller.scrollTo(0, 0);
        (scroller as any).scrollToTop();
      }
    } catch (e) {}

    try {
      handleColorMode();
    } catch (error) {}

    try {
      modalController.getTop().then((topModal) => {
        if (topModal && !to.query?.km_) modalController.dismiss(null, 'cancel');
      });
    } catch (e) {}
  }
});
