<template>
  <ion-segment
    scrollable
    mode="md"
    style="gap: 8px"
    class="tabs compact no-shadow bg-transparent customize-tabs"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button
      v-for="(tab, index) of tabs"
      :key="index"
      class="bg-transparent"
      style="
        color: #5d5c5c;
        max-width: 100%;
        border-radius: 40px;
        border: 2px solid;
        max-height: 27px;
        min-height: 25px;
      "
      :value="index"
    >
      <div>
        <ion-label style="color: #5d5c5c; font-size: 12px">{{ tab.name }}</ion-label>
      </div>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts" setup>
import { toRef } from 'vue';
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  tabs: {
    type: Array<Tab>,
    default: [] as Tab[],
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  isItems: {
    type: Boolean,
    default: false,
  },
});

const tabs = toRef(props, 'tabs');
const isItems = toRef(props, 'isItems');
const activeIndex = toRef(props, 'activeIndex');
const emits = defineEmits(['update:activeIndex', 'tab-changed']);
const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = tabs.value[tabIndex];

  emits('update:activeIndex', tabIndex);
  emits('tab-changed', tab);
};
const color = computed(() => {
  return isItems.value ? '#AE38E5' : 'var(--ion-color-primary)';
});
</script>
<style scoped lang="sass">
ion-segment-button
  --indicator-color: transparent
  --background-checked: v-bind(color)
  border-radius: 40px
  border: 2px solid #214163

.segment-button-checked
  background-color: var(--background-checked) !important
  border: none !important
  *
    color: white !important
</style>
