<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div class="d-flex align-items-center title">
            Purchase Complete <ion-icon class="mx-1 check-icon" :icon="checkmarkOutline" />
          </div>

          <div />
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content p-3">
      <div class="d-flex align-items-center justify-content-center mt-2">
        <img class="cheer-img" src="/cheer.png" />
        <div class="cheer-title">You just made someone's day!</div>
      </div>
      <div class="items-border-section p-2">
        <div class="d-flex align-items-center">
          <ion-icon class="icon mx-1" :icon="giftOutline" />
          <span class="sub-title"> Item bought:</span>
        </div>
        <VirtualItemsDisplay :selectedUser="selectedUser" :item="virtualItem" />
      </div>

      <div class="items-border-section p-2 my-2">
        <div class="d-flex align-items-center">
          <ion-icon class="icon mx-1" :icon="personCircleOutline" />
          <span class="sub-title mr-1">Recipient:</span>

          <img v-user-image class="prf-pic mx-1" :src="get(selectedUser, 'profile_picture_url')" />
          <router-link :to="{ name: 'profile', params: { username: get(selectedUser, 'username') } }">
            @{{ get(selectedUser, 'username') }}
          </router-link>
        </div>
      </div>
      <div class="user-item-sec">
        <div class="d-flex justify-content-center mx-auto title">Item sent to user</div>
      </div>
    </div>

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button color="transparent" class="close-btn" @click="dismissModal"> Close </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import VirtualItemsDisplay from '@/shared/pages/store/VirtualItemsDisplay.vue';
import { checkmarkOutline, personCircleOutline, giftOutline } from 'ionicons/icons';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  virtualItem: {
    type: Object,
    default: {},
  },
  selectedUser: {
    type: Object,
    default: {},
  },
});
const virtualItem = toRef(props, 'virtualItem');
const emits = defineEmits(['close']);
const isOpen = toRef(props, 'isOpen');
const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.cheer-title
  font: bolder 28px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #A04BFE
.cheer-img
  width: 200px
  height: 200px
  object-fit: cover
.check-icon
  font-size: 22px !important
  font-weight: bold
.icon
  font-size: 22px !important
  font-weight: bold
  color: #214163
.dark .icon
  color: white !important
.prf-pic
  width: 20px
  height: 20px
  object-fit: cover
  border-radius: 50px
.items-border-section
  border: 2px solid #DDDFE9
  border-radius: 16px
.user-item-sec
  border: 2px solid #88DC3B
  border-radius: 20px
.dark .toolbar
  --background: #17074c !important
.close-btn
  border-radius: 18px
  text-transform: none
  --background: #7050B7
  background:  #7050B7
  color: white
  height: 30px
  font-weight: 600
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 70vh
  overflow: auto
  max-height: 70dvh

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 20px
  font-weight: bold
  color: #214163
.dark .sub-title
  color: white !important
.title
  color: #88DC3B
  font-size: 20px
.dark .title
  color: #88DC3B !important
</style>
