<template>
  <ion-content ref="modal" @willDismiss="dismissModal" scroll-y="false">
    <ion-header>
      <ion-toolbar>
        <ion-title mode="md" slot="start">Share profile!</ion-title>
        <ion-buttons slot="end">
          <ion-button size="medium" @click="dismissModal">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="content modal-content flex-column">
      <div class="upper d-flex">
        <div v-if="isOwner" class="owner d-flex">
          <img src="/success.gif" class="mr-3" />
          <div class="info">
            <div class="title">WOW, YOU’RE DRIPPING WITH STYLE!</div>
            <div class="description mt-2">SCREENSHOT & LET YOUR FRIENDS KNOW YOU’RE HERE!</div>
            <div class="icons d-flex mt-2">
              <div
                v-for="(_, index) of [0, 1, 2, 3, 4, 5]"
                :key="index"
                class="one-icon d-flex justify-content-center align-items-center"
              >
                <inline-svg src="/icons/arrow-down.svg" />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <ion-button class="copy-btn not-owner mx-0 mt-2" @click="copyToClipboard">Copy link to profile</ion-button>
        </div>
      </div>
      <div v-if="!isOwner" class="or mt-auto">Or screenshot and tell people about it:</div>
      <div v-else class="or mt-auto">
        <ion-button class="copy-btn mx-0 mt-2" @click="copyToClipboard">Copy link to profile</ion-button>
      </div>
      <div class="lower d-flex flex-column flex-grow-1 px-3 pt-3 mt-3 position-relative">
        <img v-user-image class="profile position-absolute" :src="profilePicture" />
        <div class="cover w-100" :style="{ background: background }"><img v-if="cover" :src="cover" /></div>
        <div class="username w-100 mt-4 text-center">{{ username }}</div>
        <div class="characters mx-auto justify-content-center d-flex mt-3 px-0 px-md-4">
          <div
            v-for="(character, index) of characters"
            :key="index"
            class="one-character position-relative"
            :class="{ fake: character.fake }"
            :style="{ background: character.fake ? '#FFF' : '' }"
          >
            <inline-svg v-if="character.fake" src="/coming-soon.svg" class="position-absolute soon" width="65px" />
            <div class="image d-flex align-items-center justify-content-center">
              <img v-character-card-image :src="character.info?.cropProfilePicture || character.info?.profilePicture" />
            </div>

            <div v-if="!character.fake" class="name position-absolute">
              {{ character.info?.name }}
            </div>
          </div>
        </div>
        <div class="lowest py-2 px-3 mx-n3 d-flex mt-auto">
          <img class="mr-2" src="/logo-circle.png" />
          <div class="text">
            <span>Let's talk OCs on </span><a href="https://characterhub.com/">Characterhub!</a> <br /><a
              href="https://characterhub.com/"
              class="small mt-0 d-block"
              >characterhub.com</a
            >
          </div>
        </div>
      </div>
    </div></ion-content
  >
</template>

<script lang="ts" setup>
import { modalController } from '@ionic/vue';
import { profileStore } from '@/shared/pinia-store/profile';
import { authStore } from '@/shared/pinia-store/auth';
import { toast } from '@/shared/native/toast';
import { onboardingStore } from '@/shared/pinia-store/onboarding';

const { currentProfile } = profileStore();
const { currentStep } = onboardingStore();
const { currentRoute } = useRouter();
const { user } = authStore();

const ongoingBoarding = computed(() => {
  return currentRoute.value.name === 'onboarding' && currentStep.value === 19;
});
const profilePicture = computed(() =>
  ongoingBoarding.value ? get(user.value, 'profile_picture_url') : currentProfile.value?.profile_picture_url
);
const cover = computed(() =>
  ongoingBoarding.value
    ? get(user.value, 'cover_cropped_url')
    : currentProfile.value?.cover_cropped_url || currentProfile.value?.cover_url
);
const username = computed(() => (ongoingBoarding.value ? get(user.value, 'username') : currentProfile.value?.username));
const background = computed(() =>
  ongoingBoarding.value ? get(user.value, 'header_color') : currentProfile.value?.header_color
);
const characters = computed(() => {
  const featuredCharacters = ongoingBoarding.value
    ? get(user.value, 'featured_characters') || []
    : currentProfile.value?.featured_characters || [];

  if (featuredCharacters.length >= 3) {
    return featuredCharacters.slice(0, 3);
  }
  const fakeCharacters = [
    {
      fake: true,
      info: {
        profilePicture: '/rnd-ch.svg',
        name: '',
      },
    },
    {
      fake: true,
      info: {
        profilePicture: '/rnd-ch-1.svg',
        name: '',
      },
    },
    {
      fake: true,
      info: {
        profilePicture: '/rnd-ch-2.svg',
        name: '',
      },
    },
  ].slice(0, 3 - featuredCharacters.length);

  return [...featuredCharacters, ...fakeCharacters];
});

const copyToClipboard = async () => {
  try {
    const {
      public: { hostUrl },
    } = useRuntimeConfig();
    const username = ongoingBoarding.value ? get(user.value, 'username') : currentProfile.value?.username;
    navigator.clipboard.writeText(`${hostUrl}/profile/${username}`);
    await toast.show('Profile link copied to clipboard', 'nonative', 'success');
  } catch (error) {
    await toast.show('Check permissions for copying to clipboard', 'nonative', 'danger');
  }
};

const isOwner = computed(
  () =>
    currentRoute.value.name === 'profile-self' ||
    ongoingBoarding.value ||
    (currentRoute.value.name === 'profile' && currentRoute.value.params.username === user.value.username)
);

const dismissModal = (res?: any) => {
  if (!res) return modalController.dismiss(null, 'cancel');
  modalController.dismiss(res, 'done');
};
</script>

<style lang="sass" scoped>
.copy-btn
  height: 25px
  margin-bottom: 0px
  &.not-owner
    margin-bottom: 0 !important
  @media(max-width: 768px)
    margin-bottom: -4px
  @media(max-width: 450px)
    margin-bottom: -18px
.content
  display: flex
  flex-direction: row
  justify-content: space-between
  padding: 12px
  height: calc(100% - 75px)
  width: calc(100% - 24px)
  margin: 15px auto 0 auto
  border: 1px solid var(--ch-bsii-200)
.lower
  background: var(--ch-surface-primary)
  border: 2px solid var(--ch-black-color)
  width: 380px
  height: 380px
  min-height: 380px
  min-width: 380px
  max-height: 380px
  max-width: 380px
  @media(max-width: 450px)
    width: 290px
    height: 290px
    min-height: 290px
    min-width: 290px
    max-height: 290px
    max-width: 290px
    padding: 10px 10px 0 10px !important
    margin: auto
    transform: translateY(10px)
    .profile
      width: 80px !important
      height: 80px !important
      top: 35px !important
    .lowest
      font-size: 14px !important
      margin-left: -10px !important
      margin-left: -10px !important
      width: calc(100% + 20px) !important
    .cover
      height: 80px !important
    .lowest
      img
        height: 32px
        width: 35px
        min-width: 35px
    .characters
      margin-top: 10px !important
    .one-character
      width: 65px !important
      height: 65px !important
      min-width: 65px !important

  .lowest
    box-shadow: 0px -4px 12px 0px rgba(63, 48, 78, 0.12)
    border-radius: var(--border-radius-md) var(--border-radius-md) 0 0
    font-size: 20px
    font-weight: bold
    background: #FFF
    img
      width: 40px
      height: 38px
      min-width: 40px
    .small
      font-size: 12px

  .profile
    width: 110px
    height: 110px
    border: 3px var(--ch-surface-primary)
    top: 50px
    right: 0
    left: 0
    margin: auto
    border-radius: 60px
  .username
    font-size: 20px
    color: var(--ch-black-color)
    font-weight: bold
  .cover
    height: 130px
    border-radius: 10px 10px 0 0
    overflow: hidden
    img
      width: 100%
      height: 100%
      object-fit: cover
  .characters
    grid-gap: 7px
    .one-character
      background: #4A9FC3
      border: 2px solid #FFF
      border-radius: var(--border-radius-xs)
      width: 90px
      min-width: 90px
      height: 90px
      overflow: hidden
      @media(max-width: 450px)
        width: 80px
        min-width: 80px
        height: 80px
      .image
        width: 100%
        height: 100%
        img
          object-fit: cover
          width: 100%
          height: 100%
      .soon
        right: 0
        left: 0
        top: 0
        bottom: 0
        margin: auto
        z-index: 1
      .name
        color: #fff
        bottom: 4px
        font-size: 10px
        left: 4px

.upper
  border-radius: 12px
  color: var(--ch-black-color)
  max-height: 125px
  img
    max-width: 150px
    height: 100%
    min-width: 100px
    aspect-ratio: 1
    @media(max-width: 450px)
      width: 40%

    @media(max-width: 380px)
      width: 35%

  .description
    font-size: 16px
    font-weight: bold
  .title
    font-size: 14px
  .icons
    grid-gap: 7px
  .one-icon
    width: 20px
    height: 20px
    background: var(--ch-black-color)
    border-radius: var(--border-radius-xs)
</style>
