import { chAxios } from '@/shared/lib/axios';

export async function fetchSiteMessageByName(name: string) {
  const url = `/site-message/?name=${name}`;
  const cacheKey = `${name}-site-message`;
  const value = await useChFetch<any>(cacheKey, url, undefined, 300000 * 60);

  return value;
}

export async function fetchSiteCarouselPages(group: string) {
  const url = `/site-carousel/?group=${group}`;
  const cacheKey = `${group}-site-carousel`;
  const value = await useChFetch<any>(cacheKey, url, undefined, 300000 * 60);

  return value;
}
