<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="modal-inner-content">
      <div class="d-flex align-items-center my-3 px-4">
        <strong class="text-black mx-auto" style="font-size: 18px">Verification Required</strong>
        <i
          class="ti-close icon clickable-item-hov"
          style="font-size: 18px; color: lightslategray"
          @click="dismissModal"
        />
      </div>

      <div class="p-2">
        <div class="d-flex justify-content-center">
          <img src="/images/email-verif-req-320.webp" height="200" width="200" />
        </div>

        <div class="text-center">
          <h2>Please verify your email address</h2>
          <p>Begin interacting with the CharacterHub family by verifying your email "{{ user.email }}"</p>
        </div>
        <hr class="mx-5 my-2" />
        <p class="text-center">
          Didn't receive an email? <strong @click="resend" class="clickable-item-hov">Resend email</strong>
        </p>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native';
import { resendEmail } from '@/shared/actions/users';
import { authStore } from '@/shared/pinia-store/auth';
const { user } = authStore();

defineProps({
  isOpen: { type: Boolean, default: false },
});

const emits = defineEmits(['dismissModal']);

const dismissModal = (val: any) => {
  emits('dismissModal', val);
};

const resend = async () => {
  try {
    await resendEmail();
    toast.show('Email sent successfully.', 'nonative', 'success');
  } catch (error) {
    toast.show('Resend email limit reached. Try again in 6 hours.', 'nonative', 'danger');
  }
};
</script>

<style lang="sass" scoped>

ion-modal
  --height: fit-content

.modal-inner-content
  background: var(--ion-background-color, #fff)
</style>
